body {
    font-family: 'Poppins';
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Firefox */
html {
    scrollbar-color: #ccc white;
    scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.popover-arrow {
    display: none !important;
}

.popover {
    left: -10px !important;
}

.recharts-layer .recharts-pie,
.recharts-sector:focus {
    outline: none;
}
