$primaryColor: #4b74be;
$dangerColor: #e04f5f;
$darkWhiteColor: #ffffff;

.sweetalert-modal {
    border-radius: 1rem;

    .popup-html-container {
        max-height: 300px;
        font-size: 0.875rem;
        padding: 0 0.625rem;
        line-height: 1.2rem;
        b {
            color: #263757;
        }
    }

    .popup-title-error {
        color: #e57373;
    }

    .popup-title-success {
        color: #5178d6;
    }

    .popup-title-success,
    .popup-title-error {
        font-size: 1.375rem;
    }

    .popup-btn {
        min-width: 100px;
        border-radius: 6px;
        font-size: 0.875rem;
        padding: 0.313rem 0.625rem;
        margin: 0.313rem 0.5rem;
        cursor: pointer;
        color: #57647e;
        background-color: $darkWhiteColor;
        border: 1px solid #57647e;
        outline: none;
        min-height: 36px;
        &:hover {
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
        }
    }

    .popup-btn-primary {
        color: $darkWhiteColor;
        background-color: $primaryColor;
    }

    .popup-btn-secondary {
        color: $primaryColor;
        background-color: $darkWhiteColor;
    }

    .popup-btn-primary,
    .popup-btn-secondary {
        border: 1px solid $primaryColor;
    }

    .popup-btn-error {
        color: $darkWhiteColor;
        background-color: $dangerColor;
        border: 1px solid $dangerColor;
    }
}
